.wrap-text {
	@include media-query ($on-palm) {
        	padding-right: 0px;
		float: none;
	}

	padding-right: 20px;
        float: left;
}

.site-header {
	border-top: none;
	border-bottom: none;

	.site-title {
		border-left: 5px solid $visited-color;
		padding-left: 3px;
	}

	a {
		font-weight: bold;
		text-decoration: none;

		&:hover {
                	background-color: $text-color;
                	color: $background-color;
                	text-decoration: none;
                	font-weight: bold;
        	}
	}
}

.site-footer {
.social-media-list {
	a {
		&:hover {
			.username {
			text-decoration: none;
		}
		}
	}
}
}

a {
	color: $brand-color;
	padding-left: 0.1em;
	padding-right: 0.1em;
	text-decoration: none;
	font-weight: bold;

	&:visited {
		color: $visited-color;
	}

	&:hover {
		background-color: $text-color;
		color: $background-color;
		text-decoration: none;
		font-weight: bold;
	}
}

